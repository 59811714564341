body {
  padding: 0;
  margin: 0;
  font-family:  Helvetica, Arial, sans-serif;
}

input {
  font-family:  Helvetica, Arial, sans-serif;
}

.scrollBar::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
.scrollBar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}